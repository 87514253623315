import(/* webpackMode: "eager" */ "/var/www/host/frontendv2/node_modules/react-perfect-scrollbar/dist/css/styles.css");
;
import(/* webpackMode: "eager" */ "/var/www/host/frontendv2/node_modules/next/font/google/target.css?{\"path\":\"src/configs/themeConfig.ts\",\"import\":\"Hind_Siliguri\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"hindsilu\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ClickProvider"] */ "/var/www/host/frontendv2/src/app/GlobalProvider.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/host/frontendv2/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/frontendv2/src/app/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/host/frontendv2/src/assets/iconify-icons/generated-icons.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/host/frontendv2/src/libs/ToastProvider.tsx");

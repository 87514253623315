export const language_abbr: any = {
  Hindi: 'hi',
  English: 'en',
  Bengali: 'bn',
  Assamese: 'as',
  Gujarati: 'gu',
  Kannada: 'kn',
  Malayalam: 'ml',
  Marathi: 'mr',
  Punjabi: 'pa',
  Tamil: 'ta',
  Telugu: 'te',
  Urdu: 'ur',
  Odia: 'or',
};
